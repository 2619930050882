import { Component, OnInit } from '@angular/core';
import { FaConfig, FaIconLibrary } from '@fortawesome/angular-fontawesome';

/* /////////////////////// */
/* FontAwesome */

import {
  faAngry,
  faFrown,
  faMeh,
  faSmile,
  faGrinBeam,
} from '@fortawesome/pro-light-svg-icons';

import {
  faFlag,
  faUser as regularUser,
  faBadgeDollar as farBadgeDollar,
  faBoxFull as farBoxFull,
  faTicketAlt,
  faTimesCircle,
  faSearch,
  faUsers as farUsers,
  faChartBar,
  faAngleLeft,
  faAngleRight,
  faTrashAlt,
  faClipboardList,
  faCheckCircle,
  faCopy,
  faExternalLink,
  faSyncAlt,
  faFilePdf,
  faFileExcel,
  faFilePlus,
  faFileAlt as faFileAltR,
  faFileExport as faFileExportR,
  faFileCheck as faFileCheckR,
  faFileInvoiceDollar as faFileInvoiceDollarR,
  faPaste as faPasteR,
  faFileCsv,
  faChartNetwork,
  faClipboardListCheck,
  faFileExclamation as faFileExclamationR,
  faExclamation,
  faCheck,
  faCogs,
  faListAlt,
  faClock,
  faGlobe,
  faUserHeadset as faUserHeadsetR,
  faFilter,
  faArrowCircleRight,
  faStopwatch,
  faCog as faCogR,
  faTruck,
  faShoppingCart,
  faRedo,
  faUsdCircle,
  faScanner,
  faFileSpreadsheet,
  faPencil,
  faPencilAlt,
  faSave,
  faInfoCircle,
} from '@fortawesome/pro-regular-svg-icons';

import {
  faSearch as fasSearch,
  faAdjust,
  faPrint,
  faBatteryQuarter,
  faBell,
  faCircle,
  faCoffee,
  faEllipsisH,
  faFighterJet,
  faFlag as solidFlag,
  faBadgeDollar as fasBadgeDollar,
  faMagic,
  faMobile,
  faSquare,
  faSync,
  faTimes,
  faUserChart,
  faTh,
  faPlus,
  faBoxes,
  faDatabase,
  faUsers,
  faBuilding,
  faUserTie,
  faUserPlus,
  faTags,
  faHouseUser,
  faCog,
  faEye,
  faHome,
  faUserHeadset,
  faFileUpload,
  faUpload,
  faAngleDown,
  faEdit,
  faHistory,
  faFile,
  faBars,
  faBug,
  faFileAlt,
  faFileSignature,
  faFileExclamation,
  faFileCheck,
  faFileSearch,
  faFileTimes,
  faFileEdit,
  faFileExcel as faFileExcelS,
  faFileImport,
  faFileExport,
  faFileDownload,
  faFileCode,
  faFileCertificate,
  faFilePdf as fasFilePdf,
  faBoxFull as fasBoxFull,
  faHandHoldingUsd,
  faFileInvoiceDollar,
  faArchive,
  faLayerPlus,
  faTrash,
  faPaste,
  faNetworkWired,
  faStoreAlt,
  faDotCircle,
  faCodeBranch,
  faFileInvoice,
  faSignOut,
  faChartNetwork as faChartNetworkS,
  faListAlt as faListAltS,
  faSpinnerThird,
  faTools,
  faCloudUpload,
  faAnalytics,
  faFileArchive,
  faCheckDouble,
  faFolders,
  faClock as fasClock,
  faCheckCircle as fasCheckCircle,
  faTimesCircle as fasTimesCircle,
  faExclamationCircle,
  faUser as faUserS,
  faExternalLink as fasExternalLink,
  faPaperclip,
  faClone,
  faTable,
  faChartLine,
  faCubes,
  faFileContract,
  faSortUp,
  faSortDown
} from '@fortawesome/pro-solid-svg-icons';

/* /////////////////////// */
/* Chart JS */

import {
  Chart,
  ArcElement,
  LineElement,
  BarElement,
  PointElement,
  BarController,
  BubbleController,
  DoughnutController,
  LineController,
  PieController,
  PolarAreaController,
  RadarController,
  ScatterController,
  CategoryScale,
  LinearScale,
  LogarithmicScale,
  RadialLinearScale,
  TimeScale,
  TimeSeriesScale,
  Decimation,
  Filler,
  Legend,
  Title,
  Tooltip,
  SubTitle
} from 'chart.js';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {

  /*notificationsCounter = 1000;
  isSyncAnimated = true;
  magicLevel = 0;*/

  constructor(library: FaIconLibrary, faConfig: FaConfig) {

    /* FontAwesome */

    library.addIcons(
      faTh,
      faFileAlt,
      faPlus,
      faBoxes,
      faDatabase,
      faUserS,
      faUsers,
      farUsers,
      faBuilding,
      faUserTie,
      faUserPlus,
      faUserChart,
      faTags,
      faHouseUser,
      faCog,
      faCogR,
      faBell,
      faSync,
      faBug,
      faFlag,
      solidFlag,
      faTimes,
      faMagic,
      faAdjust,
      faCircle,
      faCoffee,
      faSquare,
      faMobile,
      regularUser,
      faEllipsisH,
      faFighterJet,
      faBatteryQuarter,
      faFileSignature,
      faCopy,
      faEye,
      fasBadgeDollar,
      farBadgeDollar,
      farBoxFull,
      fasBoxFull,
      faTicketAlt,
      faTimesCircle,
      faHome,
      faSearch,
      fasSearch,
      faBars,
      faUserHeadset,
      faUserHeadsetR,
      faFileUpload,
      faUpload,
      faChartBar,
      faAngleDown,
      faAngleLeft,
      faAngleRight,
      faEdit,
      faHistory,
      faFile,
      faFileExclamation,
      faFileExclamationR,
      faFileCheck,
      faFileCheckR,
      faFileSearch,
      faFileTimes,
      faFileEdit,
      faFileImport,
      faFileExport,
      faFileExportR,
      faFileInvoice,
      faFilePlus,
      faFilePdf,
      fasFilePdf,
      faFileExcel,
      faFileExcelS,
      faFileCode,
      faFileCsv,
      faFileCertificate,
      faFileDownload,
      faHandHoldingUsd,
      faFileInvoiceDollar,
      faFileInvoiceDollarR,
      faArchive,
      faLayerPlus,
      faExclamation,
      faCheck,
      faTrash,
      faTrashAlt,
      faClipboardList,
      faPaste,
      faPasteR,
      faNetworkWired,
      faStoreAlt,
      faCheck,
      faCheckCircle,
      faSpinnerThird,
      faExternalLink,
      fasExternalLink,
      faDotCircle,
      faCodeBranch,
      faSyncAlt,
      faSignOut,
      faPrint,
      faAngry,
      faFrown,
      faMeh,
      faSmile,
      faGrinBeam,
      faFileAltR,
      faChartNetwork,
      faChartNetworkS,
      faClipboardListCheck,
      faCogs,
      faListAlt,
      faListAltS,
      faClock,
      faGlobe,
      faFilter,
      faTools,
      faArrowCircleRight,
      faCloudUpload,
      faStopwatch,
      faTruck,
      faCheck,
      faShoppingCart,
      faAnalytics,
      faFileArchive,
      faRedo,
      faCheckDouble,
      faFolders,
      fasClock,
      fasCheckCircle,
      fasTimesCircle,
      faSpinnerThird,
      faUsdCircle,
      faExclamationCircle,
      faPaperclip,
      faScanner,
      faFileSpreadsheet,
      faClone,
      faTable,
      faPencil,
      faPencilAlt,
      faSave,
      faInfoCircle,
      faChartLine,
      faCubes,
      faFileContract,
      faSortUp,
      faSortDown
    );
    faConfig.fallbackIcon = faMagic;

    /* /////////////////////// */
    /* Chart JS */

    Chart.register(
      ArcElement,
      LineElement,
      BarElement,
      PointElement,
      BarController,
      BubbleController,
      DoughnutController,
      LineController,
      PieController,
      PolarAreaController,
      RadarController,
      ScatterController,
      CategoryScale,
      LinearScale,
      LogarithmicScale,
      RadialLinearScale,
      TimeScale,
      TimeSeriesScale,
      Decimation,
      Filler,
      Legend,
      Title,
      Tooltip,
      SubTitle,
    );
  }

  isIframe = false;

  ngOnInit(): void {
    this.isIframe = window !== window.parent && !window.opener;
  }
}
