import { Injectable } from "@angular/core";

import { AppConfigService } from "../app-config/app-config.service"

@Injectable({
  providedIn: "root"
})
export class RoutesService {

  constructor(private config: AppConfigService) {

  }

  get TOKEN() {
    return {
      token: this.config.Configuration.AUTENTICACAO + '/token/token',
    };
  }

  get AUTORIZACAO() {
    return {
      autorizacao: {
        expirarSenha: this.config.Configuration.AUTENTICACAO + '/autenticacao/expirar-senha',
        esquecerSenha: this.config.Configuration.AUTENTICACAO + '/autenticacao/esquecer-senha',
      },
      comum: {
        lookupUsu: this.config.Configuration.AUTENTICACAO + '/comum/lookup-ba-usu',
        lookupUsuPorNome: this.config.Configuration.AUTENTICACAO + '/comum/lookup-ba-usu-por-nome',
      },
      baGrupo: {
        listar: this.config.Configuration.AUTENTICACAO + '/ba-grupo-usu/listar',
      },
      baUsu: {
        listar: this.config.Configuration.AUTENTICACAO + '/ba-usu/listar',
        obterId: this.config.Configuration.AUTENTICACAO + '/ba-usu/obter'
      },
      permissoes: {
        listarSistemas: this.config.Configuration.AUTENTICACAO + '/permissoes/Listar-Sistemas',
        listarModulos: this.config.Configuration.AUTENTICACAO + '/permissoes/Listar-Modulos',
        listarPermissoes: this.config.Configuration.AUTENTICACAO + '/permissoes/Listar-Permissoes',
        listarTelas: this.config.Configuration.AUTENTICACAO + '/permissoes/Listar-Telas',
        adicionarPermissao: this.config.Configuration.AUTENTICACAO + '/permissoes/Adicionar-Permissao',
        listarPermissoesUsu: this.config.Configuration.AUTENTICACAO + '/permissoes/Listar-Permissoes-Usu',
        listarPermissoesGrupo: this.config.Configuration.AUTENTICACAO + '/permissoes/Listar-Permissoes-Grupo-Usu',
        detalharPermissoesUsuario: this.config.Configuration.AUTENTICACAO + '/permissoes/Detalhar-Permissoes-Usuario',
      }
    }
  } 

  get NOTIFICACAO() {
    return {
      listar: '/Listar-Notificacoes',
      marcarLida: '/Marcar-Lida',
      marcarExcluida: '/Marcar-Excluida',
    }
  } 

  get ILS() {
    return {
      expirarSenha: this.config.Configuration.ILS + "/autenticacao/expirar-senha",
      esquecerSenha: this.config.Configuration.ILS + "/autenticacao/esquecer-senha",
      lookupBaUsu: this.config.Configuration.ILS + "/comum/lookup-ba-usu",
      lookupBaUsuPorNome: this.config.Configuration.ILS + "/comum/lookup-ba-usu-por-nome",
      obterEquipamento: this.config.Configuration.ILS + "/equipamento/obter-equipamento",
      listarEquipamentos: this.config.Configuration.ILS + "/equipamento/listar-equipamentos",
      listarTiposEquipamento: this.config.Configuration.ILS + "/equipamento/listar-tipos-equipamento",
      obterLocalidade: this.config.Configuration.ILS + "/localidade/obter-localidade",
      listarLocalidades: this.config.Configuration.ILS + "/localidade/listar-localidades",
      listarCidades: this.config.Configuration.ILS + "/localidade/listar-cidades",
      obterOrganizacaoMilitar: this.config.Configuration.ILS + "/localidade/obter-organizacao-militar",
      listarOrganizacoesMilitares: this.config.Configuration.ILS + "/localidade/listar-organizacoes-militares",
      obterTaxonomia: this.config.Configuration.ILS + "/localidade/obter-taxonomia",
      obterTaxonomias: this.config.Configuration.ILS + "/localidade/obter-taxonomias",
      obterOrdemServico: this.config.Configuration.ILS + "/ordem-servico/obter-ordem-servico",
      listarOrdensServico: this.config.Configuration.ILS + "/ordem-servico/listar-ordens-servico",
      listarHistoricoOrdemServico: this.config.Configuration.ILS + "/ordem-servico/listar-historico-ordem-servico",
      listarSuprimentosOrdemServico: this.config.Configuration.ILS + "/ordem-servico/listar-suprimentos-ordem-servico",
      listarOrdensServicoGrid: this.config.Configuration.ILS + "/ordem-servico/listar-ordens-servico-grid",
      obterSuprimento: this.config.Configuration.ILS + "/suprimento/obter-suprimento",
      listarSuprimentos: this.config.Configuration.ILS + "/suprimento/listar-suprimentos",
      listarEstoquesSuprimento: this.config.Configuration.ILS + "/suprimento/listar-estoques-suprimento",
      listarMovimentacoesEstoque: this.config.Configuration.ILS + "/suprimento/listar-movimentacoes-estoque",
      incluirMovimentacaoEstoque: this.config.Configuration.ILS + "/suprimento/incluir-movimentacao-estoque",
      token: this.config.Configuration.ILS + "/token/token",
      obterUsuario: this.config.Configuration.ILS + "/usuario/obter-usuario",
      listarUsuarios: this.config.Configuration.ILS + "/usuario/listar-usuarios",
      listarTiposUsuario: this.config.Configuration.ILS + "/usuario/listar-tipos-usuario",
      listarGruposUsuario: this.config.Configuration.ILS + "/usuario/listar-grupos-usuario",
      incluirUsuario: this.config.Configuration.ILS + "/usuario/incluir-usuario",
      listarPermissoesUsuario: this.config.Configuration.ILS + "/usuario/listar-permissoes-usuario",
      incluirPermissoesUsuario: this.config.Configuration.ILS + "/usuario/incluir-permissoes-usuario",
      incluirTipoEquipamento: this.config.Configuration.ILS + "/equipamento/incluir-tipo-equipamento",
      incluirLocalidade: this.config.Configuration.ILS + "/localidade/incluir-localidade",
      incluirOrganizacaoMilitar: this.config.Configuration.ILS + "/localidade/incluir-organizacao-militar",
      incluirEquipamento: this.config.Configuration.ILS + "/equipamento/incluir-equipamento",
      incluirOrdemServico: this.config.Configuration.ILS + "/ordem-servico/incluir-ordem-servico",
      incluirSuprimento: this.config.Configuration.ILS + "/suprimento/incluir-suprimento",
      listarEstoqueSuprimentos: this.config.Configuration.ILS + "/suprimento/listar-estoque-suprimentos",
      exportarEstoqueSuprimento: this.config.Configuration.ILS + "/suprimento/exportar-estoque-suprimentos",
      dashboard: this.config.Configuration.ILS + "/dashboard/dados-dashboard",
      sequencialOrdemServico: this.config.Configuration.ILS + "/ordem-servico/obter-sequencial",
      listarContratos: this.config.Configuration.ILS + "/contrato/listar-contratos",
      incluirContrato: this.config.Configuration.ILS + "/contrato/incluir-contrato",
      obterContrato: this.config.Configuration.ILS + "/contrato/obter-contrato",
      relatorioPeriodo: this.config.Configuration.ILS + "/relatorio/periodo",
      /* Consumíveis */
      obterConsumivel: this.config.Configuration.ILS + "/consumivel/obter-consumivel",
      listarConsumiveis: this.config.Configuration.ILS + "/consumivel/listar-consumiveis",
      incluirConsumivel: this.config.Configuration.ILS + "/consumivel/incluir-consumivel",
      listarEstoquesConsumivel: this.config.Configuration.ILS + "/consumivel/listar-estoques-consumivel",
      listarMovimentacoesEstoqueConsumivel: this.config.Configuration.ILS + "/consumivel/listar-movimentacoes-estoque",
      incluirMovimentacaoEstoqueConsumivel: this.config.Configuration.ILS + "/consumivel/incluir-movimentacao-estoque",
      listarEstoqueConsumivel: this.config.Configuration.ILS + "/consumivel/listar-estoque-consumivel",
      exportarEstoqueConsumiveis: this.config.Configuration.ILS + "/consumivel/exportar-estoque-consumiveis",
      /* Exportações */
      exportarConsumiveis: this.config.Configuration.ILS + "/consumivel/exportar-consumiveis",
      exportarContratos: this.config.Configuration.ILS + "/contrato/exportar-contratos",
      exportarEquipamentos: this.config.Configuration.ILS + "/equipamento/exportar-equipamentos",
      exportarBrigadas: this.config.Configuration.ILS + "/localidade/exportar-brigadas",
      exportarOrganizacoesMilitares: this.config.Configuration.ILS + "/localidade/exportar-organizacoes-militares",
      exportarOrdensServico: this.config.Configuration.ILS + "/ordem-servico/exportar-ordens-servico",
      exportarSuprimentos: this.config.Configuration.ILS + "/suprimento/exportar-suprimentos",
      exportarRelatorioGeral: this.config.Configuration.ILS + "/relatorio/exportar-geral",
    }
  }
}
