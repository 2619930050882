import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { GetD1000PermissaoTokenUsecase } from 'src/app/core/usecases/d1000-token/get-d1000-permissao-token.usecase';
import { DetalhePermissaoModel } from 'src/app/core/domain/permissoes/detalhe-permissao.model';
import { AppMenuService } from 'src/app/services/app-menu/app-menu.service';

@Component({
  selector: 'app-route-handler',
  templateUrl: './route-handler.component.html',
})
export class RouteHandlerComponent implements OnInit {

  permissions: DetalhePermissaoModel[] = [];

  timerHandle: any;

  constructor(private getD1000PermissaoToken: GetD1000PermissaoTokenUsecase, 
              private change: ChangeDetectorRef,
              private appMenuService: AppMenuService) { }

  ngOnInit() {
    this.initCheckLogin();
  }

  async getUserId(): Promise<number> {
    let user = await lastValueFrom(this.getD1000PermissaoToken.execute());

    return parseInt(user.Chave);
  }

  async initRoutes() {
    try {
      this.change.detectChanges();

      this.appMenuService.setupRoutes();
    }
    catch (ex) {

    }
  }

  initCheckLogin() {
    this.timerHandle = setInterval(this.checkLogin.bind(this), 1000);
  }

  async checkLogin() {
    try {
      let _idUsu = await this.getUserId();

      if(!isNaN(_idUsu)) {
        clearTimeout(this.timerHandle);

        this.initRoutes();
      }
    }
    catch {

    }
  }

}
