import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { GetD1000PermissaoTokenUsecase } from 'src/app/core/usecases/d1000-token/get-d1000-permissao-token.usecase';
import { D1000PermissaoTokenModel } from 'src/app/core/utils/d1000-permissao-token.model';

@Component({
  selector: 'app-toolbar-user-button',
  templateUrl: './toolbar-user-button.component.html',
})
export class ToolbarUserButtonComponent implements OnInit {
  isOpen!: boolean;
  usuarioLogado = '';

  constructor(
    public getD1000PermissaoToken: GetD1000PermissaoTokenUsecase,
    @Inject(DOCUMENT) private _document: Document
  ) {}

  ngOnInit() {
    this.buscarUsuarioLogado();
  }

  buscarUsuarioLogado() {
    this.getD1000PermissaoToken.execute().subscribe((usuario: D1000PermissaoTokenModel) => {
      this.usuarioLogado = usuario.Nome;
    });
  }

  toggleDropdown() {
    this.isOpen = !this.isOpen;
  }

  onClickOutside() {
    this.isOpen = false;
  }

  logoutUser() {
    localStorage.clear();
    this._document.defaultView?.location.reload();
  }
}
