import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { LayoutComponent } from '../components/layout/layout.component';
import { AuthGuard } from '../guards/auth-guard.service';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full',
  },
  {
    path: 'home',
    component: LayoutComponent,
    loadChildren: () =>
      import('../presentation/home/home.module').then(
        (m) => m.HomeModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'config',
    component: LayoutComponent,
    loadChildren: () =>
      import('../presentation/configuracao/config.module').then(
        (m) => m.ConfigModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'cadastros',
    component: LayoutComponent,
    loadChildren: () =>
      import('../presentation/cadastros/cadastros.module').then(
        (m) => m.CadastrosModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'relatorios',
    component: LayoutComponent,
    loadChildren: () => import('../presentation/relatorios/relatorios.module').then(
      (m) => m.RelatoriosModule
    ),
    canActivate: [AuthGuard],
  },
  {
    path: 'auth',
    loadChildren: () => import('../presentation/auth/auth.module').then(
      (m) => m.AuthModule
    ),
  },
  { path: '**', redirectTo: 'home' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: 'enabled',
      preloadingStrategy: PreloadAllModules,
      scrollPositionRestoration: 'top',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
