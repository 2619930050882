import * as moment from 'moment';

export const exibirCompetencia = (mes: any, ano: any) => {
  try {
    if (!mes || !ano) {
      return '';
    }

    if (isNaN(parseInt(mes))) {
      return `${mes}/${ano}`;
    }

    return moment(new Date(`${ano}-${mes.toString().padStart(3, '0')}`))
      .locale('pt-br')
      .format('MMM/YYYY')
      .toString()
      .toUpperCase();
  } catch (e) {
    return '';
  }
};

export const formatarTimestamp = (timestamp: number) => {
  let duration = moment.duration(timestamp);
  let hours = (Math.floor(timestamp  / 3600000));

   if(duration.days() >= 1) {
    if( duration.minutes() <=9 ) return `${hours}:0${duration.minutes()}`;
    else return `${hours}:${duration.minutes()}`;
  }    if( duration.minutes() <=9 ) return `${hours}:0${duration.minutes()}`;
    else if(duration.hours() >= 1) {
    if (duration.hours() <= 9 && duration.minutes() <= 9) return `0${duration.hours()}:0${duration.minutes()}`;
    if (duration.hours() <= 9 && duration.minutes() > 9) return `0${duration.hours()}:${duration.minutes()}`;
    else return `${duration.hours()}:${duration.minutes()}`;
  }
  else if(duration.minutes() >= 1) {
    return `${duration.minutes()}`;
  }
  else {
    return "0";
  }
}

export const calcularDiferenca = (dataInicial: Date | string, dataFinal: Date | string) => {
  let diff = moment(dataFinal).diff(dataInicial);
  return diff
}

function padValue(value: any) {
  return value < 10 ? '0' + value : value;
}

export const formatDateAsSql = (d: any): string => {
  let date = new Date(d);

  let day = padValue(date.getDate());
  let month = padValue(date.getMonth() + 1);
  let year = date.getFullYear();

  return `${year}-${month}-${day}`;
}

export const formatDateAsString = (d: any): string => {
  let date = new Date(d);

  let day = padValue(date.getDate());
  let month = padValue(date.getMonth() + 1);
  let year = date.getFullYear();

  return `${day}/${month}/${year}`;
}

export const isDateStringValid = (d: any): boolean => {
  var regex = /([0-9]{2})\/?([0-9]{2})\/?([0-9]{4})/;
  var res = regex.exec(d);
  
  if(res === null) return false;

  return parseInt(res[3]) >= 1973 && 
         parseInt(res[2]) <= 12 && 
         parseInt(res[1]) <= moment(`${res[3]}-${res[2]}`, "YYYY-MM").daysInMonth();
}