import { Injectable, ErrorHandler, Injector, NgZone } from '@angular/core';

import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { LogService } from '../log-service/log.service';


@Injectable()
export class GlobalErrorHandlerService implements ErrorHandler {
  constructor(private injector: Injector, private logService: LogService) { }

  handleError(error: any) {
    const router = this.injector.get(Router);

    console.log('URL: ' + router.url);

    console.log('Esse erro foi pego no incerceptador !!!');
    console.log({ status: error.status, error });

    this.logService.logException(error);

    if (environment.traceRequest) {
      // eslint-disable-next-line no-restricted-syntax
      console.trace('trace error');
    }

    if (error.status === 401) {
      if (!error.error) {
        console.log('Sessão expirada! Faça login novamente para acessar o sistema.');
      } else {
        console.log(error.error);
      }
    } else if (error.status === 403) {
      if (!error.error) {
        console.log('Conteúdo não encontrado ou você não tem permissão para acessá-lo!');
      } else {
        console.log(error.error);
      }
    } else if (error.status === 500) {
      if (error.error) {
        console.log(error.error);
      } else {
        console.log(
          'Erro interno do sistema. Por favor entrar em contato com o administrador do sistema.'
        );
      }
    }
  }
}
