import { Injectable } from "@angular/core";
import { AuthorizationService } from "../authorization/authorization.service";
import { AppConfigService } from "../app-config/app-config.service";

@Injectable({
    providedIn: "root"
})
export class AppLoaderService {

    constructor(private appConfig: AppConfigService, 
                private authorizationService: AuthorizationService) {
        
    }

    async Preload() {
        await this.appConfig.LoadAppSettings();
        await this.authorizationService.getUserData();
    }
}