import { Injectable } from "@angular/core";
import { Router, NavigationEnd } from "@angular/router";
import { Store } from "@ngrx/store";
import { SetCurrentlyOpenByRouteAction } from "src/app/components/sidenav/shared/sidenav.action";
import { SidenavItem } from "src/app/components/sidenav/sidenav-item/sidenav-item.model";
import { AuthorizationService } from "../authorization/authorization.service";
import * as fromRoot from "../../reducers/index";
import * as fromSidenav from "../../components/sidenav/shared/sidenav.action";

@Injectable({
    providedIn: 'root'
})
export class AppMenuService {

    constructor(
        private store: Store<fromRoot.State>, 
        private router: Router,
        private authorizationService: AuthorizationService
    ) {}

    hasPermission(idModulo: number) {
        return true;
    }

    setupRoutes() {
        /* Set Sidenav Currently Open on Page load */
        this.router.events.subscribe((event) => {
          if (event instanceof NavigationEnd) {
            this.store.dispatch(new SetCurrentlyOpenByRouteAction(event.urlAfterRedirects));
          }
        });

        /* Clear Sidenav Items */
        this.store.dispatch(new fromSidenav.ClearSidenavItemsAction(""));
    
        /* ============================================================================================================== */
        /* Navigation */
    
        const dashboardMenu = new SidenavItem({
          name: 'Dashboard',
          icon: '',
          fontawesomeStyle: 'fas',
          fontawesomePrefix: 'chart-line',
          route: "/home",
          subItems: [],
          position: 1,
        });
    
        this.store.dispatch(new fromSidenav.AddSidenavItemAction(dashboardMenu));
    
        /* *********************************************** */
    
        const cadastroMenu = new SidenavItem({
          name: 'Cadastro',
          icon: '',
          fontawesomeStyle: 'fas',
          fontawesomePrefix: 'layer-plus',
          route: null,
          subItems: [],
          position: 1,
        });
    
        /* *********************************************** */
    
        const cadastroLocal = new SidenavItem({
          name: 'Local',
          /*fontawesomeStyle: 'fas',
            fontawesomePrefix: 'file-signature',*/
          route: null,
          parent: cadastroMenu,
          subItems: [],
          position: 1,
        });
    
        let cadastroLocalidadeSubItems: SidenavItem[] = [];
    
        if (this.hasPermission(2)) {
          cadastroLocalidadeSubItems.push(new SidenavItem({
            name: 'Brigadas',
            route: '/cadastros/brigadas',
            parent: cadastroLocal,
            subItems: [],
            position: 1,
          }));
        }
        if (this.hasPermission(3)) {
          cadastroLocalidadeSubItems.push(new SidenavItem({
            name: 'Organizações Militares',
            route: '/cadastros/organizacoes-militares',
            parent: cadastroLocal,
            subItems: [],
            position: 1,
          }));
        }
    
        if (cadastroLocal.subItems) {
          cadastroLocal.subItems.push(...cadastroLocalidadeSubItems);
        }
    
        cadastroMenu.subItems?.push(cadastroLocal);
    
        /* *********************************************** */
    
        const cadastroContratos = new SidenavItem({
          name: 'Contratos',
          /*fontawesomeStyle: 'fas',
          fontawesomePrefix: 'file-signature',*/
          route: '/cadastros/contratos',
          parent: cadastroMenu,
          subItems: [],
          position: 1,
        });
    
        cadastroMenu.subItems?.push(cadastroContratos);
    
        /* *********************************************** */
    
        const cadastroEquipamentos = new SidenavItem({
          name: 'Equipamento',
          /*fontawesomeStyle: 'fas',
            fontawesomePrefix: 'file-signature',*/
          route: null,
          parent: cadastroMenu,
          subItems: [],
          position: 1,
        });
    
        let cadastroEquipamentosSubItems: SidenavItem[] = [];
    
        if (this.hasPermission(2)) {
          cadastroEquipamentosSubItems.push(new SidenavItem({
            name: 'Configuração das OS',
            route: '/cadastros/tipos-equipamento',
            parent: cadastroEquipamentos,
            subItems: [],
            position: 1,
          }));
        }
        if (this.hasPermission(3)) {
          cadastroEquipamentosSubItems.push(new SidenavItem({
            name: 'Lista de Equipamentos',
            route: '/cadastros/equipamentos',
            parent: cadastroEquipamentos,
            subItems: [],
            position: 1,
          }));
        }
    
        if (cadastroEquipamentos.subItems) {
          cadastroEquipamentos.subItems.push(...cadastroEquipamentosSubItems);
        }
    
        cadastroMenu.subItems?.push(cadastroEquipamentos);
    
        /* *********************************************** */
    
        const cadastroSobressalentes = new SidenavItem({
          name: 'Sobressalentes',
          route: '/cadastros/sobressalentes',
          parent: cadastroMenu,
          subItems: [],
          position: 1,
        });
    
        cadastroMenu.subItems?.push(cadastroSobressalentes);
    
        /* ////////////////////////////////////////////////////////////////////////////////////////////  */
    
        const cadastroConsumiveis = new SidenavItem({
          name: 'Consumíveis',
          /*fontawesomeStyle: 'fas',
          fontawesomePrefix: 'file-signature',*/
          route: '/cadastros/consumiveis',
          parent: cadastroMenu,
          subItems: [],
          position: 1,
        });
    
        cadastroMenu.subItems?.push(cadastroConsumiveis);
    
        /* ////////////////////////////////////////////////////////////////////////////////////////////  */
    
        if((cadastroMenu?.subItems?.length ?? 0) > 0 &&
            this.authorizationService.IsInGroup([1, 2])) {
          this.store.dispatch(new fromSidenav.AddSidenavItemAction(cadastroMenu));
        }
    
        /* ////////////////////////////////////////////////////////////////////////////////////////////  */
    
        const ordemServicoMenu = new SidenavItem({
          name: 'Ordem de Serviço',
          icon: '',
          fontawesomeStyle: 'far',
          fontawesomePrefix: 'clipboard-list',
          route: "/cadastros/ordem-servico",
          subItems: [],
          position: 1,
        });
    
        this.store.dispatch(new fromSidenav.AddSidenavItemAction(ordemServicoMenu));
    
        /* ////////////////////////////////////////////////////////////////////////////////////////////  */
    
        const estoqueMenu = new SidenavItem({
          name: 'Estoque',
          icon: '',
          fontawesomeStyle: 'fas',
          fontawesomePrefix: 'cubes',
          route: "/cadastros/estoque",
          subItems: [],
          position: 1,
        });
    
        if(this.authorizationService.IsInGroup([1, 2])) {
          this.store.dispatch(new fromSidenav.AddSidenavItemAction(estoqueMenu));
        }
    
        /* ////////////////////////////////////////////////////////////////////////////////////////////  */
    
        const settingsMenu = new SidenavItem({
          name: 'Configurações',
          icon: '',
          fontawesomeStyle: 'far',
          fontawesomePrefix: 'cogs',
          route: null,
          subItems: [],
          position: 1,
        });
    
        /* *********************************************** */
    
        const permissions = new SidenavItem({
          name: 'Usuários',
          route: '/config/usuarios',
          parent: settingsMenu,
          subItems: [],
          position: 1,
        });
    
        /* *********************************************** */
        /* config */
    
        if (settingsMenu.subItems) {
          settingsMenu.subItems.push(permissions);
        }
    
        if((settingsMenu?.subItems?.length ?? 0) > 0 &&
           this.authorizationService.IsInGroup(1)) {
          this.store.dispatch(new fromSidenav.AddSidenavItemAction(settingsMenu));
        }
    
        /* ////////////////////////////////////////////////////////////////////////////////////////////  */
    
        const reportsMenu = new SidenavItem({
          name: 'Relatórios',
          icon: '',
          fontawesomeStyle: 'far',
          fontawesomePrefix: 'file-pdf',
          route: null,
          subItems: [],
          position: 1,
        });
    
        /* *********************************************** */
    
        const periodo = new SidenavItem({
          name: 'Período',
          route: '/relatorios/periodo',
          parent: reportsMenu,
          subItems: [],
          position: 1,
        });

        const geral = new SidenavItem({
          name: 'Geral',
          route: '/relatorios/geral',
          parent: reportsMenu,
          subItems: [],
          position: 2,
        });
    
        /* *********************************************** */
        /* config */
    
        if (reportsMenu.subItems) {
            /*reportsMenu.subItems.push(periodo);*/
            reportsMenu.subItems.push(geral);
        }
    
        if((reportsMenu?.subItems?.length ?? 0) > 0 &&
            this.authorizationService.IsInGroup([1, 2])) {
          this.store.dispatch(new fromSidenav.AddSidenavItemAction(reportsMenu));
        }
    
      }

}