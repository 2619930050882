import { environment } from './../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { shareReplay, retry, delay, map} from 'rxjs/operators';
import { DefaultResponse } from './default-response';

@Injectable({
  providedIn: 'root',
})
export class HttpService {
  constructor(private http: HttpClient) {}
  subscriptions = new Subscription();
  headers = new HttpHeaders();

  post<T>(
    url: string,
    body: any,
    useDefaultHeader = true,
    useFormData = false
  ): Observable<DefaultResponse<T>> {
    return this.request<T>('POST', `${url}`, body, useDefaultHeader, useFormData);
  }

  postCustom<T>(
    url: string,
    body: any,
    useDefaultHeader = true,
    useFormData = false
  ): Observable<any> {
    return this.requestCustom<T>('POST', `${url}`, body, useDefaultHeader, useFormData);
  }

  put<T>(
    url: string,
    body: any,
    useDefaultHeader = true,
    useFormData = false
  ): Observable<DefaultResponse<T>> {
    return this.request<T>('PUT', `${url}`, body, useDefaultHeader, useFormData);
  }

  patch<T>(url: string, body: any): Observable<DefaultResponse<T>> {
    return this.request<T>('PATCH', `${url}`, body);
  }

  get<T>(url: string): Observable<DefaultResponse<T>> {
    return this.request<T>('GET', `${url}`);
  }

  getCustom<T>(url: string): Observable<any> {
    return this.http.request<T>('GET', url, {
      body: null,
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    });

    
  }

  download(url: string): Observable<ArrayBuffer> {
    return this.http.get(`${url}`, {
      responseType: 'arraybuffer',
    });
  }

  delete<T>(url: string, id: number): Observable<DefaultResponse<T>> {
    return this.request<T>('DELETE', `${url}`, { id });
  }

  deleteCustom<T>(url: string, body: any, useDefaultHeader = true): Observable<DefaultResponse<T>> {
    return this.requestCustom<T>('DELETE', `${url}`, body, useDefaultHeader);
  }

  private request<T>(
    type: string,
    url: string,
    body: any = null,
    _useDefaultHeader = true,
    useFormData = false
  ): Observable<DefaultResponse<T>> {
    let headers: HttpHeaders;
    headers = this.getDefaultHeader(useFormData);

    if (environment.logRequest) {
      console.dir({ type, url, headers, body });
    }

    const response = this.http
      .request<T>(type, url, {
        body,
        headers,
      })
      .pipe(
        shareReplay(),
        retry(0),
        delay(this.randomInteger(350, 750)),
        map((x) => this.onsuccess<T>(type, x))
      );

    if (environment.traceRequest) {
      // eslint-disable-next-line no-restricted-syntax
      console.trace('trace');

      response.subscribe((res) => {
        console.log(res);
      });
    }

    return response;
  }

  private requestCustom<T>(
    type: string,
    url: string,
    body: any = null,
    _useDefaultHeader = true,
    useFormData = false
  ): Observable<any> {
    let headers: HttpHeaders;
    headers = this.getDefaultHeader(useFormData);

    if (environment.logRequest) {
      console.dir({ type, url, headers, body });
    }

    const response = this.http.request<T>(type, url, {
      body,
      headers,
    });

    if (environment.traceRequest) {
      // eslint-disable-next-line no-restricted-syntax
      console.trace('trace');

      response.subscribe((res) => {
        console.log(res);
      });
    }

    return response;
  }

  randomInteger(min: number, max: number) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  private getDefaultHeader(_useFormData = false) {
    return new HttpHeaders({});
    
  }

  private oncatch<T>(e: any) {
    const result = new DefaultResponse<T>();
    result.error(e);
    return result;
  }

  private onsuccess<T>(type: any, e: any) {
    const result = new DefaultResponse<T>();
    result.success(type, e);
    return result;
  }
}
