import { NotificacaoModel } from 'src/app/core/domain/notificacao-model';
import { Mapper } from '../../../core/base/mapper';
import { NotificacaoModelEntity } from './notificacao-web-entity';

export class NotificacaoWebRepositoryMapper extends Mapper<NotificacaoModelEntity, NotificacaoModel> {
  mapFrom(param: NotificacaoModelEntity): NotificacaoModel {
    return {
      idUsu: param.idUsu,
      idFonte: param.idFonte,
      dtExecucao: param.dtExecucao
    };
  }

  mapTo(param: NotificacaoModel): NotificacaoModelEntity {
    return {
      idUsu: param.idUsu,
      idFonte: param.idFonte,
      dtExecucao: param.dtExecucao
    };
  }
}
