import { SingleD1000ResponseModel } from '../../../core/utils/single-d1000-response-model';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BaseHttpService } from 'src/app/services/http/base-http.service';
import { AutenticacaoModel } from 'src/app/core/domain/autenticacao.model';
import { AutenticacaoWebEntity } from './autenticacao-web-entity';
import { AutenticacaoWebRepositoryMapper } from './autenticacao-web-repository-mapper';
import { AutenticacaoRepository } from 'src/app/core/repositories/autenticacao.repository';
import { RoutesService } from 'src/app/services/routing/routes.service';

@Injectable({
  providedIn: 'root',
})
export class AutenticacaoWebRepository extends AutenticacaoRepository {
  mapper = new AutenticacaoWebRepositoryMapper();

  constructor(public http: BaseHttpService, public routes: RoutesService) {
    super();
  }

  postExpirarSenha(
    param: AutenticacaoModel
  ): Observable<SingleD1000ResponseModel<AutenticacaoModel>> {
    var request = this.http
      .post<SingleD1000ResponseModel<AutenticacaoWebEntity>>(
        `${this.routes.AUTORIZACAO.autorizacao.expirarSenha}`,
        this.mapper.mapTo(param)
      )
      .pipe(
        map((x) => {
          return this.mapper.responseWebMapFrom(x.data);
        })
      );

    return request;
  }

  postEsquecerSenha(
    param: AutenticacaoModel
  ): Observable<SingleD1000ResponseModel<AutenticacaoModel>> {
    var request = this.http
      .post<SingleD1000ResponseModel<AutenticacaoWebEntity>>(
        `${this.routes.AUTORIZACAO.autorizacao.esquecerSenha}`,
        this.mapper.mapTo(param)
      )
      .pipe(
        map((x) => {
          return this.mapper.responseWebMapFrom(x.data);
        })
      );

    return request;
  }
}
