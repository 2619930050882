import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';

@Component({
  selector: 'app-quickpanel',
  templateUrl: './quickpanel.component.html',
})
export class QuickpanelComponent implements OnInit {
  todayDay!: string;
  todayDate!: string;
  todayDateSuffix!: string;
  todayMonth!: string;

  ngOnInit() {
    this.todayDay = moment().format('dddd');
    this.todayDate = moment().format('Do');
    this.todayDate = this.todayDate.replace(/\D/g, '');
    this.todayDateSuffix = moment().format('Do');
    this.todayDateSuffix = this.todayDateSuffix.replace(/d/g, '');
    this.todayMonth = moment().format('MMMM');
  }
}
