import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { ListarNotificacaoModel, NotificacaoModel } from 'src/app/core/domain/notificacao-model';
import { SingleD1000ResponseModel } from 'src/app/core/utils/single-d1000-response-model';
import { BaseHttpService } from 'src/app/services/http/base-http.service';
import { RoutesService } from 'src/app/services/routing/routes.service';
import { NotificacaoModelEntity } from './notificacao-web-entity';
import { NotificacaoWebRepositoryMapper } from './notificacao-web-repository-mapper';

@Injectable({
  providedIn: 'root',
})
export class NotificacaoWebRepository {
  mapper = new NotificacaoWebRepositoryMapper();

  constructor(public http: BaseHttpService, public routes: RoutesService) {
  }

  getNotificacao(model: any): Observable<SingleD1000ResponseModel<ListarNotificacaoModel>> {
    return this.http.getAll<any>(`${this.routes.NOTIFICACAO.listar}?idUsu=${model}`).pipe(
      map((x) => {
        return x;
      })
    );
  }

  marcarLida(model: any): Observable<SingleD1000ResponseModel<NotificacaoModel>> {
    return this.http
      .put<SingleD1000ResponseModel<NotificacaoModelEntity>>(
        `${this.routes.NOTIFICACAO.marcarLida}`,
        model
      )
      .pipe(
        map((x) => {
          return x.data;
        })
      );

  }

  marcarExcluida(model: any): Observable<SingleD1000ResponseModel<NotificacaoModel>> {
    var request = this.http
      .put<SingleD1000ResponseModel<NotificacaoModelEntity>>(
        `${this.routes.NOTIFICACAO.marcarExcluida}`,
        model, true
      );

    return request.pipe(
      map((x) => {
        return x.data;
      })
    );
  }
}
