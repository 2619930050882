import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { lastValueFrom } from "rxjs";
import { AppConfigModel } from "src/app/core/utils/app-config.model";

@Injectable({
    providedIn: "root"
})
export class AppConfigService {

    public Configuration!: AppConfigModel;

    constructor(private http: HttpClient) {
        
    }

    async LoadAppSettings() {
      let configuration = await lastValueFrom(this.http.get<AppConfigModel>("./assets/config.json"));
      
      this.Configuration = configuration;
    }
}