import { Component, Input } from '@angular/core';
import { SidenavItem } from '../../../sidenav/sidenav-item/sidenav-item.model';

@Component({
  selector: 'app-toolbar-navigation-item',
  templateUrl: './toolbar-navigation-item.component.html',
})
export class ToolbarNavigationItemComponent {
  @Input('item')
  item!: SidenavItem;
  @Input('currentlyOpen') currentlyOpen: SidenavItem[] = [];

}
