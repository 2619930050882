export const environment = {
  production: false,
  disablePermissions: false,
  disableAuthGuard: false,
  logRequest: false,
  traceRequest: false,
  googleMapsApiKey: '',
  HOME: 'http://localhost:4200/home',
  EXEMPLOCRUD: 'https://localhost:44304/api/v1/exemplo-crud/',
  ApplicationInsights: {
    InstrumentationKey: "88f88075-a3cb-4eb7-b4fb-e206e2e1c4fc"
  }
};
