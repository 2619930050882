import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { LIST_FADE_ANIMATION } from '../../utils/list.animation';
import { NotificacaoWebRepository } from 'src/app/data/repository/notificacao-web-repository/notificacao-web.repository';
import { GetD1000PermissaoTokenUsecase } from 'src/app/core/usecases/d1000-token/get-d1000-permissao-token.usecase';
import { D1000PermissaoTokenModel } from 'src/app/core/utils/d1000-permissao-token.model';
import { NotificacaoModel } from 'src/app/core/domain/notificacao-model';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HttpErrorResponse } from '@angular/common/http';
import { Router} from '@angular/router';

@Component({
  selector: 'app-toolbar-notifications',
  templateUrl: './toolbar-notifications.component.html',
  animations: [...LIST_FADE_ANIMATION],
})
export class ToolbarNotificationsComponent implements OnInit {
  isOpen!: boolean;

  data!: any;

  idUser = '';
  model!: any;

  demoTriggers = 0;
  isNew = 1;
  newNotifications = 0;
  intervalHandle: any = false;
  isNotification = false;

  notifications: any[] = [];
  constructor(
    private cd: ChangeDetectorRef,
    private router: Router,
    private change: ChangeDetectorRef,
    private repo: NotificacaoWebRepository,
    private getD1000PermissaoToken: GetD1000PermissaoTokenUsecase,
    public snackBar: MatSnackBar
  ) { }

  ngOnInit() {
    /*this.getUserId();*/
  }

  // --------------------------------

  getUserId() {
    this.getD1000PermissaoToken.execute().subscribe((usuario: D1000PermissaoTokenModel) => {
      this.idUser = usuario.Chave;

      this.getNotifications();

      this.initTimer();
    });
  }

  initTimer() {
    this.intervalHandle = setInterval(() => {
      this.getNotifications();
    }, 120000);
  }

  getNotifications() {
    this.repo.getNotificacao(this.idUser).subscribe((r: any) => {
      this.notifications.length = 0;

      r.data.forEach((d: any) => {
        this.notifications.push({
          fontawesomeStyle: 'fas',
          fontawesomePrefix: d.erro ? 'bug' : 'bell',
          name: d.descricao,
          time: new Date(d.dtExecucao),
          sTime: '',
          read: d.lida,
          idUsu: d.idUsu,
          idFonte: d.idFonte,
          fonte: d.fonte,
          dtExecucao: d.dtExecucao,
          colorClass: this.isRead(d),
        });
      });
    });

    this.orderNotifications();
  }

  isRead(d: any) {
    if (!d.erro && !d.lida) {
      this.isNew = 0;
      return 'new';
    }
    if (d.erro && !d.lida) {
      this.isNew = 0;
      return 'error';
    }

    return '';
  }

  setRead(d: any) {
    this.model = {
      idUsu: d.idUsu,
      idFonte: d.idFonte,
      dtExecucao: d.dtExecucao,
    } as NotificacaoModel;

    if ((d.colorClass === 'new' && d.read) || (d.colorClass === 'error' && d.read)) {
      d.read = true;
      d.colorClass = '';
    }

    this.repo.marcarLida([this.model]).subscribe((r: any) => {
      if (r.codigo === 200) {
        this.isNew = 1;
        this.newsCounter();
      } else
        this.snackBar.open('Não foi possível marcar como lida a notificação', '', {
          duration: 3500,
        });
    });
  }

  markAllAsRead() {
    this.notifications.forEach((d: any) => {
      if (d.read === false) {
        this.isNew = 1;
        this.setRead(d)
      }
    });

    this.newsCounter();
  }

  dismiss(d: any) {
    this.model = {
      idUsu: d.idUsu,
      idFonte: d.idFonte,
      dtExecucao: d.dtExecucao,
    } as NotificacaoModel;

    try {
      this.repo.marcarExcluida([this.model]).subscribe((r: any) => {
        if (r.codigo === 200) {
          this.notifications.splice(d, 1);
          this.change.detectChanges();
          this.newsCounter();
        } else this.snackBar.open('Não foi possível excluir a notificação', '', { duration: 3500 });
      });
    } catch (ex) {
      if (ex instanceof HttpErrorResponse) {
        (ex.error.title === undefined)
          this.snackBar.open('Não foi possível excluir a notificação', '', { duration: 3500 });
      } else {
        this.snackBar.open('Não foi possível excluir a notificação', '', { duration: 3500 });
      }
    }
  }

  toggleDropdown() {
    this.isOpen = !this.isOpen;
    this.getNotifications();
  }

  onClickOutside() {
    this.isOpen = false;
  }

  daysDiff(before: any, current: any) {
    return moment(current, 'DD/MM/YYYY').diff(moment(before, 'DD/MM/YYYY'), 'days') + 1;
  }

  newsCounter() {
    let i = 0;
    this.notifications.forEach((d: any) => {
      if (d.colorClass === 'new' || d.colorClass === 'error') i += 1;
    });

    this.newNotifications = i;
    if (i > 0) this.isNotification = true;

    this.change.detectChanges();
  }

  orderNotifications() {
    this.notifications.forEach((d: any) => {
      if (d.Time === undefined) d.Time = d.time.getTime();
    });

    this.notifications.sort((a: any, b: any) => {
      if (a.time < b.time) return 1;
      else return -1;
    });

    this.notifications.forEach((d: any) => {
      d.sTime = moment(d.time).locale('pt-br').fromNow();

      if (this.daysDiff(d.time, new Date()) > 5)
        d.sTime = d.time.toLocaleDateString('pt-BR') + ' (' + d.sTime + ')';
    });

    this.newsCounter();
  }

  goToNotification(notification: any) {
    this.router.navigateByUrl(this.redirectByType(notification.fonte));
  }

  redirectByType(fonte: any) {
/*     if(fonte === 'Importação') return '/suite/importar';
    else return '/home';
 */
     switch (fonte) {
      case 'Importação':
        return '/suite/importar';
      case 'ContratoVerba':
        return 'contrato-verba/consulta';
      case 'DesbloqueioUsuario':
        return '/desbloqueio-usuario';
      default:
        return '/home';
    }
  }
}
