import { NativeDateAdapter } from "@angular/material/core";

export class CustomDateAdapter extends NativeDateAdapter  {

    parse(value: any): Date | null {
        let prBrDateRegex = /([0-9]{2})\/?([0-9]{2})\/?([0-9]{4})/;
        if(!prBrDateRegex.test((value ?? "").toString())) return null;

        try {
            let dateData = prBrDateRegex.exec(value);
            if(!dateData) return null;

            let day = parseInt(dateData[1]),
                month = parseInt(dateData[2]),
                year = parseInt(dateData[3]);

            if(day < 1 || day > 31) return null;
            if(month < 1 || month > 12) return null;
    
            return new Date(year, month - 1, day);
        }
        catch {
            return null;
        }
    }

}