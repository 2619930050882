import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BaseHttpService } from 'src/app/services/http/base-http.service';
import { RoutesService } from 'src/app/services/routing/routes.service';
import { SingleD1000ResponseModel } from 'src/app/core/utils/single-d1000-response-model';
import { LookupModel } from 'src/app/core/utils/lookup.model';
import { ListD1000ResponseModel } from 'src/app/core/utils/list-d1000-response-model';
import { EquipamentoModel } from 'src/app/core/domain/ils/equipamento.model';
import { TipoEquipamentoModel } from 'src/app/core/domain/ils/tipoequipamento.model';
import { LocalidadeModel } from 'src/app/core/domain/ils/localidade.model';
import { OrganizacaoMilitarModel } from 'src/app/core/domain/ils/organizacaomilitar.model';
import { TaxonomiaModel } from 'src/app/core/domain/ils/taxonomia.model';
import { OrdemServicoModel } from 'src/app/core/domain/ils/ordemservico.model';
import { OrdemServicoHistoricoModel } from 'src/app/core/domain/ils/ordemservicohistorico.model';
import { SuprimentoModel } from 'src/app/core/domain/ils/suprimento.model';
import { OrdemServicoSuprimentoModel } from 'src/app/core/domain/ils/ordemservicosuprimento.model';
import { EstoqueSuprimentoModel } from 'src/app/core/domain/ils/estoquesuprimento.model';
import { MovimentacaoEstoqueSuprimentoModel } from 'src/app/core/domain/ils/movimentacaoestoquesuprimento.model';
import { UsuarioModel } from 'src/app/core/domain/ils/usuario.model';
import { TiposUsuarioModel } from 'src/app/core/domain/ils/tiposusuario.model';
import { GruposModel } from 'src/app/core/domain/ils/grupos.model';
import { FiltroDashboardModel } from 'src/app/core/domain/ils/filtro-dashboard.model';
import { DadosDashboardModel } from 'src/app/core/domain/ils/dados-dashboard.model';
import { CidadeModel } from 'src/app/core/domain/ils/cidade.model';
import { OrdemServicoSequencialModel } from 'src/app/core/domain/ils/ordem-servico-sequencial.model';
import { ContratoModel } from 'src/app/core/domain/ils/contrato.model';
import { EstoqueSuprimentoViewModel } from 'src/app/core/domain/ils/estoquesuprimentoview.model';
import { FiltroRelatorioPeriodoModel } from 'src/app/core/domain/ils/filtro-relatorio-periodo.model';
import { RelatorioPeriodoModel } from 'src/app/core/domain/ils/relatorio-periodo.model';
import { ConsumivelModel } from 'src/app/core/domain/ils/consumivel.model';
import { EstoqueConsumivelModel } from 'src/app/core/domain/ils/estoqueconsumivel.model';
import { EstoqueConsumivelViewModel } from 'src/app/core/domain/ils/estoqueconsumivelview.model';
import { MovimentacaoEstoqueConsumivelModel } from 'src/app/core/domain/ils/movimentacaoestoquesconsumivel.model';
import { PermissaoUsuarioModel } from 'src/app/core/domain/ils/permissaousuario.model';
import { ListaOrdemServicoModel } from 'src/app/core/domain/ils/listaordemservico.model';
import { formatDateAsSql } from 'src/app/services/formats/date';

@Injectable({
  providedIn: 'root',
})
export class IlsWebRepository {

  constructor(public http: BaseHttpService, public routes: RoutesService) {
    
  }

  ​lookupBaUsu(): Observable<ListD1000ResponseModel<LookupModel>> {
    return this.http
      .getAll<ListD1000ResponseModel<LookupModel>>(
        `${this.routes.ILS.lookupBaUsu}`
      )
      .pipe(
        map((x) => {
          return x.data;
        })
      );
  }

  ​lookupBaUsuPorNome(nome: string): Observable<ListD1000ResponseModel<LookupModel>> {
    return this.http
      .getAll<ListD1000ResponseModel<LookupModel>>(
        `${this.routes.ILS.​lookupBaUsuPorNome}/${nome}`
      )
      .pipe(
        map((x) => {
          return x.data;
        })
      );
  }

  ​obterEquipamento(id: number): Observable<SingleD1000ResponseModel<EquipamentoModel>> {
    return this.http
      .get<SingleD1000ResponseModel<EquipamentoModel>>(
        `${this.routes.ILS.​obterEquipamento}`,
        id
      )
      .pipe(
        map((x) => {
          return x.data;
        })
      );
  }

  ​listarEquipamentos(): Observable<ListD1000ResponseModel<EquipamentoModel>> {
    return this.http
      .getAll<ListD1000ResponseModel<EquipamentoModel>>(
        `${this.routes.ILS.​listarEquipamentos}`
      )
      .pipe(
        map((x) => {
          return x.data;
        })
      );
  }

  ​listarTiposEquipamento(): Observable<ListD1000ResponseModel<TipoEquipamentoModel>> {
    return this.http
      .getAll<ListD1000ResponseModel<TipoEquipamentoModel>>(
        `${this.routes.ILS.​listarTiposEquipamento}`
      )
      .pipe(
        map((x) => {
          return x.data;
        })
      );
  }

  ​obterLocalidade(id: number): Observable<SingleD1000ResponseModel<LocalidadeModel>> {
    return this.http
      .get<SingleD1000ResponseModel<LocalidadeModel>>(
        `${this.routes.ILS.​obterLocalidade}`,
        id
      )
      .pipe(
        map((x) => {
          return x.data;
        })
      );
  }

  ​listarLocalidades(): Observable<ListD1000ResponseModel<LocalidadeModel>> {
    return this.http
      .getAll<ListD1000ResponseModel<LocalidadeModel>>(
        `${this.routes.ILS.​listarLocalidades}`
      )
      .pipe(
        map((x) => {
          return x.data;
        })
      );
  }

  listarCidades(): Observable<ListD1000ResponseModel<CidadeModel>> {
    return this.http
      .getAll<ListD1000ResponseModel<CidadeModel>>(
        `${this.routes.ILS.listarCidades}`
      )
      .pipe(
        map((x) => {
          return x.data;
        })
      );
  }

  ​obterOrganizacaoMilitar(id: number): Observable<SingleD1000ResponseModel<OrganizacaoMilitarModel>> {
    return this.http
      .get<SingleD1000ResponseModel<OrganizacaoMilitarModel>>(
        `${this.routes.ILS.​obterOrganizacaoMilitar}`,
        id
      )
      .pipe(
        map((x) => {
          return x.data;
        })
      );
  }

  ​listarOrganizacoesMilitares(): Observable<ListD1000ResponseModel<OrganizacaoMilitarModel>> {
    return this.http
      .getAll<ListD1000ResponseModel<OrganizacaoMilitarModel>>(
        `${this.routes.ILS.​listarOrganizacoesMilitares}`
      )
      .pipe(
        map((x) => {
          return x.data;
        })
      );
  }

  ​obterTaxonomia(id: number): Observable<SingleD1000ResponseModel<TaxonomiaModel>> {
    return this.http
      .get<SingleD1000ResponseModel<TaxonomiaModel>>(
        `${this.routes.ILS.​obterTaxonomia}`,
        id
      )
      .pipe(
        map((x) => {
          return x.data;
        })
      );
  }

  ​obterTaxonomias(): Observable<ListD1000ResponseModel<TaxonomiaModel>> {
    return this.http
      .getAll<ListD1000ResponseModel<TaxonomiaModel>>(
        `${this.routes.ILS.​obterTaxonomias}`
      )
      .pipe(
        map((x) => {
          return x.data;
        })
      );
  }

  ​obterOrdemServico​(id: number): Observable<SingleD1000ResponseModel<OrdemServicoModel>> {
    return this.http
      .get<SingleD1000ResponseModel<OrdemServicoModel>>(
        `${this.routes.ILS.​obterOrdemServico​}`,
        id
      )
      .pipe(
        map((x) => {
          return x.data;
        })
      );
  }

  ​listarOrdensServico(): Observable<ListD1000ResponseModel<OrdemServicoModel>> {
    return this.http
      .getAll<ListD1000ResponseModel<OrdemServicoModel>>(
        `${this.routes.ILS.​listarOrdensServico}`
      )
      .pipe(
        map((x) => {
          return x.data;
        })
      );
  }

  ​listarHistoricoOrdemServico(id: number): Observable<ListD1000ResponseModel<OrdemServicoHistoricoModel>> {
    return this.http
      .get<ListD1000ResponseModel<OrdemServicoHistoricoModel>>(
        `${this.routes.ILS.​listarHistoricoOrdemServico}`,
        id
      )
      .pipe(
        map((x) => {
          return x.data;
        })
      );
  }

  listarSuprimentosOrdemServico(id: number): Observable<ListD1000ResponseModel<OrdemServicoSuprimentoModel>> {
    return this.http
      .get<ListD1000ResponseModel<OrdemServicoSuprimentoModel>>(
        `${this.routes.ILS.​listarSuprimentosOrdemServico}`,
        id
      )
      .pipe(
        map((x) => {
          return x.data;
        })
      );
  }

  listarOrdensServicoGrid(dataInicio: Date | null | undefined = undefined, 
                          dataFim: Date | null | undefined = undefined, 
                          exibirRelatoriosFalhaFinalizados: boolean = false,
                          considerarPeriodoAbertura: boolean = true): Observable<ListD1000ResponseModel<ListaOrdemServicoModel>> {
    let url = `${this.routes.ILS.listarOrdensServicoGrid}?exibirRelatoriosFalhaFinalizados=${exibirRelatoriosFalhaFinalizados}`;

    if(dataInicio && dataFim && considerarPeriodoAbertura) {
      url += `&dataInicio=${formatDateAsSql(dataInicio)}&dataFim=${formatDateAsSql(dataFim)}`
    }

    return this.http
      .getAll<ListD1000ResponseModel<ListaOrdemServicoModel>>(
        url
      )
      .pipe(
        map((x) => {
          return x.data;
        })
      );
  }

  ​obterSuprimento​(id: number): Observable<SingleD1000ResponseModel<SuprimentoModel>> {
    return this.http
      .getAll<SingleD1000ResponseModel<SuprimentoModel>>(
        `${this.routes.ILS.​obterSuprimento​}`
      )
      .pipe(
        map((x) => {
          return x.data;
        })
      );
  }

  ​listarSuprimentos(): Observable<ListD1000ResponseModel<SuprimentoModel>> {
    return this.http
      .getAll<ListD1000ResponseModel<SuprimentoModel>>(
        `${this.routes.ILS.​listarSuprimentos}`
      )
      .pipe(
        map((x) => {
          return x.data;
        })
      );
  }

  incluirSuprimento(dados: SuprimentoModel): Observable<SingleD1000ResponseModel<SuprimentoModel>> {
    return this.http
      .post<SingleD1000ResponseModel<SuprimentoModel>>(
        `${this.routes.ILS.incluirSuprimento}`,
        dados
      )
      .pipe(
        map((x) => {
          return x.data;
        })
      );
  }

  listarEstoquesSuprimento(id: number): Observable<ListD1000ResponseModel<EstoqueSuprimentoModel>> {
    return this.http
      .get<ListD1000ResponseModel<EstoqueSuprimentoModel>>(
        `${this.routes.ILS.​listarEstoquesSuprimento}`,
        id
      )
      .pipe(
        map((x) => {
          return x.data;
        })
      );
  }

  listarMovimentacoesEstoque(id: number): Observable<ListD1000ResponseModel<MovimentacaoEstoqueSuprimentoModel>> {
    return this.http
      .get<ListD1000ResponseModel<MovimentacaoEstoqueSuprimentoModel>>(
        `${this.routes.ILS.listarMovimentacoesEstoque}`,
        id
      )
      .pipe(
        map((x) => {
          return x.data;
        })
      );
  }

  incluirMovimentacaoEstoque(dados: MovimentacaoEstoqueSuprimentoModel): Observable<SingleD1000ResponseModel<MovimentacaoEstoqueSuprimentoModel>> {
    return this.http
      .post<SingleD1000ResponseModel<MovimentacaoEstoqueSuprimentoModel>>(
        `${this.routes.ILS.incluirMovimentacaoEstoque}`,
        dados
      )
      .pipe(
        map((x) => {
          return x.data;
        })
      );
  }

  obterUsuario​(id: number): Observable<SingleD1000ResponseModel<UsuarioModel>> {
    return this.http
      .get<SingleD1000ResponseModel<UsuarioModel>>(
        `${this.routes.ILS.​obterUsuario​}`,
        id
      )
      .pipe(
        map((x) => {
          return x.data;
        })
      );
  }

  listarUsuarios(): Observable<ListD1000ResponseModel<UsuarioModel>> {
    return this.http
      .getAll<ListD1000ResponseModel<UsuarioModel>>(
        `${this.routes.ILS.​listarUsuarios}`
      )
      .pipe(
        map((x) => {
          return x.data;
        })
      );
  }

  listarTiposUsuario(): Observable<ListD1000ResponseModel<TiposUsuarioModel>> {
    return this.http
      .getAll<ListD1000ResponseModel<TiposUsuarioModel>>(
        `${this.routes.ILS.​listarTiposUsuario}`
      )
      .pipe(
        map((x) => {
          return x.data;
        })
      );
  }

  listarGruposUsuario(): Observable<ListD1000ResponseModel<GruposModel>> {
    return this.http
      .getAll<ListD1000ResponseModel<GruposModel>>(
        `${this.routes.ILS.​listarGruposUsuario}`
      )
      .pipe(
        map((x) => {
          return x.data;
        })
      );
  }

  incluirUsuario(dados: UsuarioModel): Observable<SingleD1000ResponseModel<UsuarioModel>> {
    return this.http
      .post<SingleD1000ResponseModel<UsuarioModel>>(
        `${this.routes.ILS.incluirUsuario}`,
        dados
      )
      .pipe(
        map((x) => {
          return x.data;
        })
      );
  }

  listarPermissoesUsuario(idUsuario: number): Observable<ListD1000ResponseModel<PermissaoUsuarioModel>> {
    return this.http
      .getAll<ListD1000ResponseModel<PermissaoUsuarioModel>>(
        `${this.routes.ILS.listarPermissoesUsuario}/${idUsuario}`
      )
      .pipe(
        map((x) => {
          return x.data;
        })
      );
  }

  incluirPermissoesUsuario(idUsuario: number, idTipoPermissao: number, permissoes: PermissaoUsuarioModel[]): Observable<SingleD1000ResponseModel<UsuarioModel>> {
    return this.http
      .post<SingleD1000ResponseModel<UsuarioModel>>(
        `${this.routes.ILS.incluirPermissoesUsuario}/${idUsuario}/${idTipoPermissao}`,
        permissoes
      )
      .pipe(
        map((x) => {
          return x.data;
        })
      );
  }

  incluirTipoEquipamento(dados: TipoEquipamentoModel): Observable<SingleD1000ResponseModel<TipoEquipamentoModel>> {
    return this.http
      .post<SingleD1000ResponseModel<TipoEquipamentoModel>>(
        `${this.routes.ILS.incluirTipoEquipamento}`,
        dados
      )
      .pipe(
        map((x) => {
          return x.data;
        })
      );
  }

  incluirLocalidade(dados: LocalidadeModel): Observable<SingleD1000ResponseModel<LocalidadeModel>> {
    return this.http
      .post<SingleD1000ResponseModel<LocalidadeModel>>(
        `${this.routes.ILS.incluirLocalidade}`,
        dados
      )
      .pipe(
        map((x) => {
          return x.data;
        })
      );
  }

  incluirOrganizacaoMilitar(dados: OrganizacaoMilitarModel): Observable<SingleD1000ResponseModel<OrganizacaoMilitarModel>> {
    return this.http
      .post<SingleD1000ResponseModel<OrganizacaoMilitarModel>>(
        `${this.routes.ILS.incluirOrganizacaoMilitar}`,
        dados
      )
      .pipe(
        map((x) => {
          return x.data;
        })
      );
  }

  incluirEquipamento(dados: EquipamentoModel): Observable<SingleD1000ResponseModel<EquipamentoModel>> {
    return this.http
      .post<SingleD1000ResponseModel<EquipamentoModel>>(
        `${this.routes.ILS.incluirEquipamento}`,
        dados
      )
      .pipe(
        map((x) => {
          return x.data;
        })
      );
  }

  incluirOrdemServico(dados: OrdemServicoModel): Observable<SingleD1000ResponseModel<OrdemServicoModel>> {
    return this.http
      .post<SingleD1000ResponseModel<OrdemServicoModel>>(
        `${this.routes.ILS.incluirOrdemServico}`,
        dados
      )
      .pipe(
        map((x) => {
          return x.data;
        })
      );
  }

  obterDadosDashboard(filtro: FiltroDashboardModel): Observable<SingleD1000ResponseModel<DadosDashboardModel>> {
    return this.http
      .post<SingleD1000ResponseModel<DadosDashboardModel>>(
        `${this.routes.ILS.dashboard}`,
        filtro
      )
      .pipe(
        map((x) => {
          return x.data;
        })
      );
  }

  obterSequencialOrdemServico(relatorioFalha: boolean, idOrganizacaoMilitar: number, idTipoEquipamento: number): Observable<SingleD1000ResponseModel<OrdemServicoSequencialModel>> {
    let url = `${this.routes.ILS.sequencialOrdemServico}?relatorioFalha=${relatorioFalha}&idOrganizacaoMilitar=${idOrganizacaoMilitar}&idTipoEquipamento=${idTipoEquipamento}`;

    return this.http
      .getCustom<SingleD1000ResponseModel<OrdemServicoSequencialModel>>(url)
      .pipe(
        map((x) => {
          return x.data;
        })
      );
  }

  listarContratos(): Observable<ListD1000ResponseModel<ContratoModel>> {
    return this.http
      .getAll<ListD1000ResponseModel<ContratoModel>>(
        `${this.routes.ILS.listarContratos}`
      )
      .pipe(
        map((x) => {
          return x.data;
        })
      );
  }

  obterContrato(id: number): Observable<SingleD1000ResponseModel<ContratoModel>> {
    return this.http
      .get<SingleD1000ResponseModel<ContratoModel>>(
        `${this.routes.ILS.obterContrato}`,
        id
      )
      .pipe(
        map((x) => {
          return x.data;
        })
      );
  }

  incluirContrato(dados: ContratoModel): Observable<SingleD1000ResponseModel<ContratoModel>> {
    return this.http
      .post<SingleD1000ResponseModel<ContratoModel>>(
        `${this.routes.ILS.incluirContrato}`,
        dados
      )
      .pipe(
        map((x) => {
          return x.data;
        })
      );
  }

  listarEstoqueSuprimentos(): Observable<ListD1000ResponseModel<EstoqueSuprimentoViewModel>> {
    return this.http
      .getAll<ListD1000ResponseModel<EstoqueSuprimentoViewModel>>(
        `${this.routes.ILS.listarEstoqueSuprimentos}`
      )
      .pipe(
        map((x) => {
          return x.data;
        })
      );
  }

  exportarEstoqueSuprimentos(): string {
    return this.routes.ILS.exportarEstoqueSuprimento;
  }

  obterRelatorioPeriodo(filtro: FiltroRelatorioPeriodoModel): Observable<SingleD1000ResponseModel<RelatorioPeriodoModel>> {
    return this.http
      .post<SingleD1000ResponseModel<RelatorioPeriodoModel>>(
        `${this.routes.ILS.relatorioPeriodo}`,
        filtro
      )
      .pipe(
        map((x) => {
          return x.data;
        })
      );
  }

  exportarRelatorioGeral(dataInicio: Date, dataFim: Date): string {
    return `${this.routes.ILS.exportarRelatorioGeral}?dataInicio=${formatDateAsSql(dataInicio)}&dataFim=${formatDateAsSql(dataFim)}`;
  }

  /* Consumiveis */

  obterConsumivel(id: number): Observable<SingleD1000ResponseModel<ConsumivelModel>> {
    return this.http
      .getAll<SingleD1000ResponseModel<ConsumivelModel>>(
        `${this.routes.ILS.obterConsumivel}`
      )
      .pipe(
        map((x) => {
          return x.data;
        })
      );
  }

  listarConsumiveis(): Observable<ListD1000ResponseModel<ConsumivelModel>> {
    return this.http
      .getAll<ListD1000ResponseModel<ConsumivelModel>>(
        `${this.routes.ILS.listarConsumiveis}`
      )
      .pipe(
        map((x) => {
          return x.data;
        })
      );
  }

  incluirConsumivel(dados: ConsumivelModel): Observable<SingleD1000ResponseModel<ConsumivelModel>> {
    return this.http
      .post<SingleD1000ResponseModel<ConsumivelModel>>(
        `${this.routes.ILS.incluirConsumivel}`,
        dados
      )
      .pipe(
        map((x) => {
          return x.data;
        })
      );
  }

  listarEstoquesConsumivel(id: number): Observable<ListD1000ResponseModel<EstoqueConsumivelModel>> {
    return this.http
      .get<ListD1000ResponseModel<EstoqueConsumivelModel>>(
        `${this.routes.ILS.listarEstoquesConsumivel}`,
        id
      )
      .pipe(
        map((x) => {
          return x.data;
        })
      );
  }

  listarMovimentacoesEstoqueConsumivel(id: number): Observable<ListD1000ResponseModel<MovimentacaoEstoqueConsumivelModel>> {
    return this.http
      .get<ListD1000ResponseModel<MovimentacaoEstoqueConsumivelModel>>(
        `${this.routes.ILS.listarMovimentacoesEstoqueConsumivel}`,
        id
      )
      .pipe(
        map((x) => {
          return x.data;
        })
      );
  }

  incluirMovimentacaoEstoqueConsumivel(dados: MovimentacaoEstoqueConsumivelModel): Observable<SingleD1000ResponseModel<MovimentacaoEstoqueConsumivelModel>> {
    return this.http
      .post<SingleD1000ResponseModel<MovimentacaoEstoqueConsumivelModel>>(
        `${this.routes.ILS.incluirMovimentacaoEstoqueConsumivel}`,
        dados
      )
      .pipe(
        map((x) => {
          return x.data;
        })
      );
  }

  listarEstoqueConsumiveis(): Observable<ListD1000ResponseModel<EstoqueConsumivelViewModel>> {
    return this.http
      .getAll<ListD1000ResponseModel<EstoqueConsumivelViewModel>>(
        `${this.routes.ILS.listarEstoqueConsumivel}`
      )
      .pipe(
        map((x) => {
          return x.data;
        })
      );
  }

  exportarEstoqueConsumiveis(): string {
    return this.routes.ILS.exportarEstoqueConsumiveis;
  }

  exportarConsumiveis(): string {
    return this.routes.ILS.exportarConsumiveis;
  }

  exportarContratos(): string {
    return this.routes.ILS.exportarContratos;
  }

  exportarEquipamentos(): string {
    return this.routes.ILS.exportarEquipamentos;
  }

  exportarBrigadas(): string {
    return this.routes.ILS.exportarBrigadas;
  }

  exportarOrganizacoesMilitares(): string {
    return this.routes.ILS.exportarOrganizacoesMilitares;
  }

  exportarOrdensServico(): string {
    return this.routes.ILS.exportarOrdensServico;
  }

  exportarSuprimentos(): string {
    return this.routes.ILS.exportarSuprimentos;
  }

}
