import {
  CommonModule,
  PathLocationStrategy,
  LocationStrategy,
  APP_BASE_HREF,
  PlatformLocation,
} from '@angular/common';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app.routing';
import { AutenticacaoRepository } from '../core/repositories/autenticacao.repository';
import { AutenticacaoWebRepository } from '../data/repository/autenticacao-web-reporitory/autenticacao-web.repository';
import { AuthInterceptor } from '../services/auth-interceptor/auth.interceptor';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { ComponentsModule } from '../components/components.module';
import { CoreModule } from '@angular/flex-layout';
import { D1000TokenRepository } from '../core/repositories/d1000-token.repository';
import { D1000TokenWebRepository } from '../data/repository/d1000-token-web-repository/d1000-token-web.repository';
import { DataModule } from '../data/data.module';
import { EffectsModule } from '@ngrx/effects';
import { APP_INITIALIZER, ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { MatNativeDateModule, MAT_DATE_LOCALE, DateAdapter } from '@angular/material/core';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { RouteHandlerModule } from '../components/route-handler/route-handler.module';
import { StoreModule } from '@ngrx/store';
import { TokenRepository } from '../core/repositories/token.repository';
import { reducers } from '../reducers';
import { MAT_TOOLTIP_SCROLL_STRATEGY } from '@angular/material/tooltip';
import { Overlay } from '@angular/cdk/overlay';
import { MatInputModule } from '@angular/material/input';

import { MatDatepickerModule } from '@angular/material/datepicker';
import { ClipboardModule } from 'ngx-clipboard';
import { RoutesService } from '../services/routing/routes.service';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { registerLocaleData } from '@angular/common';
import localePt from '@angular/common/locales/pt';
import { TokenWebRepository } from '../data/repository/token-web-reporitory/token-web.repository';
import { GlobalErrorHandlerService } from '../services/error-handler/global-error-handler.service';
import { AppLoaderService } from '../services/app-loader/app-loader.service';
import { CustomDateAdapter } from '../components/utils/custom-date-adapter';

registerLocaleData(localePt);

export function getBaseHref(platformLocation: PlatformLocation): string {
  return platformLocation.getBaseHrefFromDOM();
}

export const configFactory = (system: AppLoaderService) => {
  return () => system.Preload();
};

@NgModule({
  declarations: [AppComponent],
  imports: [
    AppRoutingModule,
    BrowserAnimationsModule,
    BrowserModule,
    CommonModule,
    ComponentsModule,
    CoreModule,
    DataModule,
    FontAwesomeModule,
    HttpClientModule,
    RouteHandlerModule,
    MatDialogModule,
    MatInputModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatAutocompleteModule,
    StoreModule.forRoot(reducers),
    EffectsModule.forRoot([]),
    ClipboardModule,
    MatSnackBarModule,
    LeafletModule
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: configFactory,
      deps: [AppLoaderService],
      multi: true,
    },
    {
      provide: APP_BASE_HREF,
      useFactory: getBaseHref,
      deps: [PlatformLocation],
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    RoutesService,
    { provide: MatDialogRef, useValue: {} },
    { provide: MAT_DATE_LOCALE, useValue: 'pt-BR' },
    { provide: LOCALE_ID, useValue: 'pt-BR' },
    { provide: DateAdapter, useClass: CustomDateAdapter },
    { provide: ErrorHandler, useClass: GlobalErrorHandlerService },
    { provide: LocationStrategy, useClass: PathLocationStrategy },
    { provide: D1000TokenRepository, useClass: D1000TokenWebRepository },
    { provide: AutenticacaoRepository, useClass: AutenticacaoWebRepository },
    { provide: TokenRepository, useClass: TokenWebRepository }, 
    {
      provide: MAT_TOOLTIP_SCROLL_STRATEGY,
      deps: [Overlay],
      useFactory: function (overlay: any) { return () => overlay.scrollStrategies.close(); }
    }
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
