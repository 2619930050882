import { Injectable } from "@angular/core";
import { lastValueFrom } from "rxjs";
import { PermissaoUsuarioModel } from "src/app/core/domain/ils/permissaousuario.model";
import { UsuarioModel } from "src/app/core/domain/ils/usuario.model";
import { GetD1000PermissaoTokenUsecase } from "src/app/core/usecases/d1000-token/get-d1000-permissao-token.usecase";
import { IlsWebRepository } from "src/app/data/repository/ils-web-repository/ils-web-repository";

@Injectable({
    providedIn: 'root'
})
export class AuthorizationService {

    public currentUser: UsuarioModel | undefined;
    public permissions: PermissaoUsuarioModel[] = [];

    isLoading: boolean = false;

    constructor(private ilsRepository: IlsWebRepository, 
                private tokenUsecase: GetD1000PermissaoTokenUsecase) {
        
    }

    public async getUserData() {
        if(this.isLoading) return;

        this.isLoading = true;

        try {
            let user = await lastValueFrom(this.tokenUsecase.execute());
            let id = parseInt(user.Chave);

            let usuarioResult = await lastValueFrom(this.ilsRepository.obterUsuario(id));
            let permissoesResult = await lastValueFrom(this.ilsRepository.listarPermissoesUsuario(id));

            if(!usuarioResult.sucesso || !permissoesResult.sucesso) {
                return;
            }

            this.currentUser = usuarioResult.data;
            this.permissions = permissoesResult.data ?? [];            
        }
        catch(ex) {
            console.log(ex);
        }
        finally {
            this.isLoading = false;
        }
    }

    get CurrentUser(): UsuarioModel | undefined {
        return this.currentUser;
    }

    get UserGroup(): number | undefined {
        return this.currentUser?.grupos?.at(0)?.idGrupo;
    }

    public HasPermission(type: number, permission: number) {
        return this.permissions.some(p => p.idTipoPermissao === type && p.permissao === permission) ||
               this.currentUser?.grupos?.some(x => x.idGrupo === 1 || x.idGrupo === 2);
    }

    public IsInGroup(group: number[] | number) {
        if(typeof(group) === "number") return this.UserGroup === group;

        return group.some(g => this.UserGroup === g);
    }

}